import { Address } from '../../interfaces';

export interface LicenseSubscriptiondata {
  subscriptionId: number;
  numberOfUsers: number;
}
export interface LicenseOverviewModel {
  id: number;
  companyName: string;
  orgNumber: string;
  customerNumber: string;
  administrator: string;
  isDeleted: boolean;
  createdAt: string;
  invoiceRef?: string;
  subscriptions?: number[];
  isGodkjentVaatromsbedrift: boolean | null;
  usersPerSubscriptions?: LicenseSubscriptiondata[];
  licensesPerSubscription?: LicenseSubscriptiondata[];
  code?: string;
  codeName?: string;
  numberOfEmployees?: number;
  address?: Address;
  totalPageViews?: number;
  lockedAt?: string;
}

export interface ISettings {
  //Column visible
  showColumn: boolean;
  //Name in GUI
  name: string;
  //Name to send to API when showColumn=true
  columnName: string;
  //Information to show in tooltip
  infoText: string;
}

export const initialSettings: ISettings[] = [
  {
    showColumn: true,
    name: 'Firma',
    columnName: 'companyName',
    infoText: '',
  },
  {
    showColumn: true,
    name: 'Addresse',
    columnName: 'address',
    infoText: '',
  },

  {
    showColumn: true,
    name: 'Org. nummer',
    columnName: 'orgNumber',
    infoText: '',
  },
  {
    showColumn: true,
    name: 'Kundenummer (Maconomy)',
    columnName: 'customerNumber',
    infoText: '',
  },
  {
    showColumn: true,
    name: 'Administrator',
    columnName: 'administrator',
    infoText: 'Administrator av lisens hos kunden',
  },
  {
    showColumn: true,
    name: 'Opprettet',
    columnName: 'createdAt',
    infoText: '',
  },
  {
    showColumn: false,
    name: 'Fakturareferanse',
    columnName: 'invoiceRef',
    infoText: '',
  },
  {
    showColumn: false,
    name: 'Adresse 1',
    columnName: 'address1',
    infoText: '',
  },
  {
    showColumn: false,
    name: 'Abonnement',
    columnName: 'subscriptions',
    infoText:
      'Abonnementstype: \nBFS: Byggforskserien komplett \nBFSB: Delserie Byggdetaljer \nBFSP: Delserie planlegging \nBFSF: Delserie Byggforvaltning  \nBFSU: Delserie Utførelse \nBVN: Byggebransjens våtromsnorm\nPrøve:',
  },
  {
    showColumn: false,
    name: 'Godkjent Våtromsbedrift',
    columnName: 'isGodkjentVaatromsbedrift',
    infoText: 'Informasjon fra FFV om hvorvidt kunden er godkjent våtromsbedrift',
  },
  {
    showColumn: false,
    name: 'Reg. brukere per abonnement',
    columnName: 'usersPerSubscription',
    infoText: 'Antall registrerte brukere per abonnement',
  },
  {
    showColumn: false,
    name: 'Bet. brukere per abonnement',
    columnName: 'licensesPerSubscription',
    infoText: 'Antall betalende brukere per abonnement',
  },
  {
    showColumn: false,
    name: 'Næringskode',
    columnName: 'industryCode',
    infoText: 'Næringskode hentet fra Brønnøysundregistrene (https://www.brreg.no/)\nHentes ved første oppslag, lagres i minne neste 24 timer.',
  },
  {
    showColumn: false,
    name: 'Ansatte#',
    columnName: 'numberOfEmployees',
    infoText: 'Antall ansatte hentet fra Brønnøysundregistrene (https://www.brreg.no/)\nHentes ved første oppslag, lagres i minne neste 24 timer.',
  },
  {
    showColumn: false,
    name: 'Postnummer',
    columnName: 'postalcode',
    infoText: '',
  },
  {
    showColumn: false,
    name: 'Land',
    columnName: 'country',
    infoText: '',
  },
  {
    showColumn: false,
    name: 'Sidevisninger',
    columnName: 'totalPageViews',
    infoText:
      'Sidevisninger for lisens. \nTotalt over alle anvisninger siste 12 måneder.\nDet vi si at det kan være forskjell i sidevisninger og sum av brukersidevisninger på den enkelte lisens.' +
      '\nDette gjelder spesielt for IP lisenser.' +
      '\nHentet fra BKS.\nHentes ved første oppslag, lagres i minne neste 24 timer.',
  },
  {
    showColumn: false,
    name: 'Låst dato',
    columnName: 'lockedAt',
    infoText: '',
  },
];

export function SubscriptionName(subscription: number) {
  let day;
  switch (subscription) {
    case 9:
      day = 'BFS';
      break;
    case 10:
      day = 'BFSB';
      break;
    case 11:
      day = 'BFSP';
      break;
    case 12:
      day = 'BFSF';
      break;
    case 13:
      day = 'BFSU';
      break;
    case 16:
      day = 'BVN';
      break;
    case 19:
      day = 'Prøve';
      break;
    default:
      day = '-';
  }

  return day;
}

export function showColumn(column: string, items: ISettings[]) {
  const ind = items.map((c) => c.columnName).indexOf(column);
  if (ind >= 0) return items[ind].showColumn;
  else return false;
}
